import {DefaultComponentAnalyticsContext as AnalyticsContext} from '__deprecated__/constants/loginDefault';
import {MonorailSchema} from '__deprecated__/common/analytics';
import {produceMonorailEvent} from '__deprecated__/common/analytics/MonorailTracker';
import {CloseRequestedReason} from '__deprecated__/types/shopSheetModal';

import DefaultComponentMonorailTracker from './DefaultComponentMonorailTracker';

export default class ShopPayCheckoutSheetMonorailTracker extends DefaultComponentMonorailTracker {
  /**
   * @param {object} params The constructor params.
   * @param {string} params.elementName The name of the element (e.g. `shop-pay-button`, `shop-login-button`, `shopify-payment-terms`).
   * @param {string} params.analyticsTraceId A UUID that can correlate all analytics events fired for the same user flow. I.e. Could be
   * used to correlate events between Shop JS and Pay for the Shop Login flow.
   * @param {AnalyticsContext} params.analyticsContext The context of the analytics event.
   * This is used to dismabinuate between different flows that use the same default component.
   * @param {string} params.checkoutVersion A checkout version such as "classic" or "checkout_one_checkout_sheet"
   * @param {number} params.shopId The numeric id of the shop.
   * @param {number} params.shopPermanentDomain The 'myshopify' domain of the shop.
   * @param {string} params.checkoutToken A checkout token if present.
   */
  constructor({
    elementName,
    analyticsTraceId,
    analyticsContext,
    shopId,
    shopPermanentDomain,
    checkoutToken,
    checkoutVersion,
  }: {
    elementName: string;
    analyticsTraceId: string;
    analyticsContext: AnalyticsContext;
    checkoutVersion: string;
    shopId?: number;
    shopPermanentDomain?: string;
    checkoutToken?: string;
  }) {
    super({
      elementName,
      analyticsTraceId,
      analyticsContext,
      shopId,
      shopPermanentDomain,
      checkoutToken,
      checkoutVersion,
    });
  }

  /**
   * Fired when the shop-pay-checkout-sheet is closed
   * @param {CloseRequestedReason} reason The reason the modal was closed
   */
  trackModalClosed(reason?: CloseRequestedReason) {
    const payload = {
      checkoutToken: this._checkoutToken || '',
      shopId: this._shopId,
      shopifyDomain: this._shopPermanentDomain,
      action: getCloseAction(reason || ''),
      checkoutVersion: this._checkoutVersion,
    };

    produceMonorailEvent({
      schemaId: MonorailSchema.ShopifyPayPaymentPageUiInteraction,
      payload,
    });
  }
}

/**
 * @param {string} reason The reason the modal was closed
 * @returns {string} The action to be sent as part of the ShopifyPayPaymentPageUiInteraction monorail event
 */
function getCloseAction(reason: string) {
  return (
    {
      closeButton: 'close_sheet_x_click',
      overlay: 'close_sheet_outside_click',
      keyboard: 'close_sheet_keyboard_click',
    }[reason] ?? 'close_sheet_other'
  );
}
